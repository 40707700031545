import './index.css'

const Textarea = (props) => {
    const keypress = (event) => {
        const alphanumericValue = event.target.value.replace(/[^\d\s]/g, '');

        props.onAlter(alphanumericValue);
    }

    return (
        <div className="floating-label-content">
            <textarea onChange={keypress} value={ props.value } className="floating-input" id={ props.id } placeholder="" name={ props.id } disabled={props.disabled} required="" autoComplete="" autoFocus="" />
            <label className="floating-label">{ props.label }</label>
        </div>
    )
}

export default Textarea