import './index.css'

function index() {
    return (
        <div className="background">
            <img src="/images/logo-conecta.png"></img>
        </div>
    )
}

export default index