import './index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const AlertNotas = () => {
    return (
        <div>
            <FontAwesomeIcon icon={faTriangleExclamation} className="fa-shake" style={{ color: 'red' }} />
        </div>
    )
}

export default AlertNotas