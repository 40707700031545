import React, { useEffect, useState } from 'react';
import './index.css';
import AlertNotas from '../AlertNotas';
import Textarea from '../Textarea';

const Modal = ({ isOpen, onClose, notas }) => {
    const [chave, setChave] = useState('')

    useEffect(() => {
        setChave(notas)
    }, [notas])

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
        <div className="modal">
            <AlertNotas />
            <Textarea id="chave_undefined" label="Chaves não encontradas" required="false" value={ chave } onAlter={ value => setChave(value) } disabled={true} />
            <button className="close-button" onClick={onClose}>
            Fechar
            </button>
        </div>
        </div>
    );
};

export default Modal;
