import React, { useEffect, useState } from 'react';
import './index.css'

const Button = ({status}) => {

    const [disabled, setDisabled] = useState('')

    useEffect(() => {
        const alteraStatus = () => {
            const button = document.querySelector('.icon')
    
            if(status === 'waiting'){
                button.classList.add('waiting')
            } else if(status === 'running') {
                setTimeout(() => {
                    button.classList.remove('waiting')
                    button.classList.add('running')
                }, 5000);
            } else if(status === '') {
                setTimeout(() => {
                    button.classList.remove('running')
                }, 10000);
            }

            setDisabled(true)

            setTimeout(() => {
                setDisabled(false)
            }, 10000);
        }
        alteraStatus()
    }, [status]);

    return (
        <button className="icon" disabled={ disabled } >
            <div className="cloud">
                <div className="puff puff-1"></div>
                <div className="puff puff-2"></div>
                <div className="puff puff-3"></div>
                <div className="puff puff-4"></div>
                <div className="puff puff-5"></div>
                <div className="puff puff-6"></div>
                <div className="puff puff-7"></div>
                <div className="puff puff-8"></div>
                <div className="puff puff-9"></div>
                <div className="puff puff-10"></div>
                <div className="cloud-core"></div>
                <div className="check"></div>
                <div className="arrow">
                    <div className="arrow-stem">
                        <div className="arrow-l-tip"></div>
                        <div className="arrow-r-tip"></div>
                    </div>
                </div>
            </div>
            <div className="preload">
                <div className="drop drop-1"></div>
                <div className="drop drop-2"></div>
                <div className="drop drop-3"></div>
            </div>
            <div className="progress"></div>
        </button>
    )
}

export default Button