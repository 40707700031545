import { useState } from 'react'
import Button from '../ButtonPlus/index'
import Textarea from '../Textarea/index'
import './index.css'
import axios from 'axios';
import FileSaver from 'file-saver'
import Modal from '../Modal/index';

const Form = () => {
    
    const [chave, setChave] = useState('')

    const [status, setStatus] = useState('')

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [naoEncontradas, setNaoEncontradas] = useState('');
    
    const onSubmitForm = (event) => {
        event.preventDefault()
        
        if (chave !== ''){
            postChavesNf(chave)
        }
    }

    const postChavesNf = async (chaves) => {
        chaves = chaves.split('\n')
    
        try {
            setStatus('waiting')

            const resposta = await axios.post('https://manifestos.devinectar.com.br/gen_zip', {
                "chave_nota": chaves
            })

            let link = resposta.data.path_arquivo
            
            let chaves_unseted = resposta.data.nao_encontradas
            
            if(chaves_unseted.length > 0){
                setTimeout(() => {
                    setModalIsOpen(true)
                }, 11000);

                setNaoEncontradas(chaves_unseted)
            }

            await downloadZip(link)

            setStatus('')

        } catch (error) {
            console.log(error);
            setStatus('running')

            setTimeout(() => {
                 setStatus('')
            }, 5000);
        }
    }
    
    const downloadZip = async (link) => {
        try {
            setStatus('running')

            const response = await axios.post("https://manifestos.devinectar.com.br/download_zip", {'path_zip': link}, 
            {
                'responseType': "blob"
            }
            );

            const blob = new Blob([response.data], {
                type: 'application/zip',
            })
        
            FileSaver.saveAs(blob, `notas_fiscais.zip`)

            setStatus('')

        } catch (error) {
            console.log(error);   
            setStatus('running')

            setTimeout(() => {
                 setStatus('')
            }, 5000);
        }
    }

    return (
        <div>
            <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} notas={naoEncontradas} />
            <div className="card">
                <form onSubmit={onSubmitForm}>
                    <Textarea id="chave" label="Chave da Nota" required="true" value={ chave } onAlter={ value => setChave(value) } disabled={false} />
                    <Button status={ status } />
                </form>
            </div>
        </div>
    )
}

export default Form