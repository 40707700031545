import './App.css';
import Base from './components/BaseBackground/index';
import Form from './components/Form';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Base />
        <Form />
      </div>
    </div>
  );
}

export default App;
